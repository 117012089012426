import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

const useCompanyReportType = () => {
  const toast = useToast();

  const getReportTypeList = async () => {
    try {
      return await store.dispatch('companyReports/getReportTypes');
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error fetching report types list',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  return {
    // Method
    getReportTypeList,
  };
};

export default useCompanyReportType;
