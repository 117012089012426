<template>
  <b-card>

    <b-row>
      <div class="d-flex flex-row w-100 mb-1">
        <b-col
          cols="8"
          class="d-flex justify-content-between flex-column"
        >
          <!-- Company Avatar & Action Buttons -->
          <div class="d-flex justify-content-start align-items-center">
            <b-avatar
              :text="avatarText(companyData.name)"
              :variant="`light-secondary`"
              size="55px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <h4>
                {{ companyData.name }}
              </h4>
            </div>
          </div>
        </b-col>

        <b-col
          cols="4"
          class="d-flex justify-content-end"
        >
          <b-dropdown
            variant="link"
            no-caret
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="openAccountStatementModal">
              <span>Acc. Statement</span>
            </b-dropdown-item>

            <b-dropdown-item v-b-modal.company-shipping-modal>
              <span>Booking</span>
            </b-dropdown-item>

            <b-dropdown-item v-b-modal.company-post-modal>
              <span>Posts</span>
            </b-dropdown-item>

            <b-dropdown-item v-b-modal.company-invoice-modal>
              <span>Invoice</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </div>

      <b-col
        cols="12"
        md="8"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Email</span>
            </th>
            <td>
              {{ companyData.email }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact Num</span>
            </th>
            <td>
              {{ companyData.contactTel }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Pays Admin Fee</span>
            </th>
            <td>
              <b-badge
                pill
                :variant="(companyData.paysAdminFee) ? 'light-success' : 'light-secondary'"
                class="text-capitalize"
              >
                {{ (companyData.paysAdminFee) ? 'Yes' : 'No' }}
              </b-badge>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <company-account-statement-modal ref="accountStatementModalRef" :company-emails="$props.companyData.email" />
    <company-booking-modal :company-emails="$props.companyData.email" />
    <company-post-modal :company-emails="$props.companyData.email" />
    <company-invoice-modal :company-emails="$props.companyData.email" />
  </b-card>
</template>

<script>
import { avatarText } from '@core/utils/filter';
import { BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BRow } from 'bootstrap-vue';
import { ref } from '@vue/composition-api'
import CompanyAccountStatementModal from '@/modules/company/components/CompanyAccountStatementModal.vue'
import CompanyBookingModal from '@/modules/company/components/CompanyBookingModal.vue'
import CompanyInvoiceModal from '@/modules/company/components/CompanyInvoiceModal.vue'
import CompanyPostModal from '@/modules/company/components/CompanyPostModal.vue';
import useCompany from '../composables/useCompany';

export default {
  props: {
    companyData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { deleteCompany } = useCompany();

    const accountStatementModalRef = ref(null);

    const removeCompany = async () => {
      await deleteCompany(props.companyData);
    };

    const openAccountStatementModal = () => {
      accountStatementModalRef.value.$refs.refModal.show();
    }

    return {
      //Reactive
      accountStatementModalRef,

      //Method
      removeCompany,
      openAccountStatementModal,

      // Filter
      avatarText,
    };
  },
  components: {
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BRow,
    CompanyAccountStatementModal,
    CompanyBookingModal,
    CompanyInvoiceModal,
    CompanyPostModal
  },
};
</script>
