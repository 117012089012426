<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="companyData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching company data
      </h4>
      <div class="alert-body">
        No company found with this company id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'company-list' }"
        >
          Company List
        </b-link>
        for other companies.
      </div>
    </b-alert>

    <template v-if="companyData">
      <company-setting-button />
      <b-row>
        <b-col cols="12">
          <company-card :company-data="companyData" />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col md="6">
          <company-transaction-card />
        </b-col>
        <b-col md="6">
          <company-transaction-payout-card />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import { BAlert, BCol, BLink, BRow } from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import CompanyCard from '@/modules/company/components/CompanyCard.vue';
import CompanySettingButton from '@/modules/company/components/CompanySettingButton.vue';
import CompanyTransactionCard from '@/modules/company/components/CompanyTransactionCard.vue';
import CompanyTransactionPayoutCard from '@/modules/company/components/CompanyTransactionPayoutCard.vue';
import router from '@/router';
import useCompany from '@/modules/company/composables/useCompany';

export default {
  setup() {
    const { fetchCompany } = useCompany();
    const toast = useToast();

    const companyData = ref(null);

    fetchCompany(router.currentRoute.params.id)
      .then(company => { companyData.value = company; })
      .catch(_ => { companyData.value = undefined; });

    return {
      // Reactive
      companyData,
    };
  },
  components: {
    BAlert,
    BCol,
    BLink,
    BRow,
    CompanyCard,
    CompanyTransactionCard,
    CompanyTransactionPayoutCard,
    CompanySettingButton
  }
};
</script>
