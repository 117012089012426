import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useCompanyBooking = () => {
  const toast = useToast();

  const downloadBookingCompany = async (companyId, params) => {
    try {
      return await store.dispatch('company/downloadBookingCompany', {
        companyId,
        params,
      });
    } catch (error) {
      const title =
        error.response.status === 404
          ? 'No booking found'
          : 'Error downloading the company bookings';
          toast.error({
        component: ToastificationContent,
        props: {
          title,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };
  
  const downloadInvoiceCompany = async (companyId, params) => {
    try {
      return await store.dispatch('company/downloadInvoiceCompany', {
        companyId,
        params,
      });
    } catch (error) {
      const title =
        error.response.status === 404
          ? 'No booking found'
          : 'Error downloading the company invoice';
          toast.error({
        component: ToastificationContent,
        props: {
          title,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const sendInvoiceCompany = async (companyId, params) => {
    try {
      await store.dispatch('company/sendInvoiceCompany', {
        companyId,
        params,
      });

      toast.success({
        component: ToastificationContent,
        props: {
          title: 'Email sent successfully!',
          icon: 'CheckIcon',
        },
      });
      return true;
    } catch (error) {
      const title =
        error.response.status === 404
          ? 'No bookings found'
          : 'Error sending the email';
      toast.error({
        component: ToastificationContent,
        props: {
          title,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const sendBookingCompany = async (companyId, params) => {
    try {
      await store.dispatch('company/sendBookingCompany', {
        companyId,
        params,
      });

      toast.success({
        component: ToastificationContent,
        props: {
          title: 'Email sent successfully!',
          icon: 'CheckIcon',
        },
      });
      return true;
    } catch (error) {
      const title =
        error.response.status === 404
          ? 'No post found'
          : 'Error sending the email';
      toast.error({
        component: ToastificationContent,
        props: {
          title,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  return {
    // Method
    downloadBookingCompany,
    sendBookingCompany,
    sendInvoiceCompany,
    downloadInvoiceCompany
  };
};

export default useCompanyBooking;
