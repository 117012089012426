var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"refModal",attrs:{"id":"company-auto-report-form-modal","title":"Report Details","ok-title":(_vm.reportData.id) ? 'Update' : 'Add',"no-close-on-backdrop":"","cancel-variant":"outline-secondary"},on:{"ok":_vm.validateForm}},[_c('validation-observer',{ref:"newCompanyReportFrom"},[_c('b-form',{attrs:{"id":"add-new-company-report"}},[_c('validation-provider',{attrs:{"name":"Report Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Report Type","label-for":"report-type"}},[_c('v-select',{attrs:{"options":_vm.reportTypes,"label":"name","reduce":function (options) { return options.id; },"input-id":"report-type"},on:{"input":function (reportTypeId) { return _vm.reportSelected(reportTypeId); }},model:{value:(_vm.reportData.reportTypeId),callback:function ($$v) {_vm.$set(_vm.reportData, "reportTypeId", $$v)},expression:"reportData.reportTypeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Frequency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Frequency","label-for":"frequency"}},[_c('v-select',{attrs:{"options":_vm.frequencyList,"label":"label","reduce":function (options) { return options.value; },"input-id":"frequency"},on:{"input":function (option) { return _vm.frequencySelected(option); }},model:{value:(_vm.frequencyValue),callback:function ($$v) {_vm.frequencyValue=$$v},expression:"frequencyValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Is Active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Is Active","label-for":"is-active"}},[_c('v-select',{attrs:{"options":[{ label: 'Yes', value: true }, { label: 'No', value: false }],"label":"label","reduce":function (options) { return options.value; },"input-id":"is-active"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:(value) ? 'bg-primary' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:(value) ? 'bg-primary' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.reportData.isActive),callback:function ($$v) {_vm.$set(_vm.reportData, "isActive", $$v)},expression:"reportData.isActive"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Date From","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Date From","label-for":"date-to"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ dateFormat: 'd/m/Y', enableTime: false }},model:{value:(_vm.reportData.startDateUtc),callback:function ($$v) {_vm.$set(_vm.reportData, "startDateUtc", $$v)},expression:"reportData.startDateUtc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),(_vm.reportType.isTransaction)?_c('validation-provider',{attrs:{"name":"Transactions Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Transaction Status","label-for":"transaction-status"}},[_c('v-select',{attrs:{"options":_vm.transactionStatusList,"label":"name","reduce":function (transaction) { return transaction.id; },"input-id":"transaction-status","multiple":""},model:{value:(_vm.reportData.transactionStatusIds),callback:function ($$v) {_vm.$set(_vm.reportData, "transactionStatusIds", $$v)},expression:"reportData.transactionStatusIds"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,168093075)}):_vm._e(),(_vm.reportType.isBooking)?_c('validation-provider',{attrs:{"name":"Booking Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Booking Status","label-for":"booking-status"}},[_c('v-select',{attrs:{"options":_vm.bookingStatusList,"label":"name","reduce":function (status) { return status.id; },"input-id":"booking-status","multiple":""},model:{value:(_vm.reportData.bookingStatusIds),callback:function ($$v) {_vm.$set(_vm.reportData, "bookingStatusIds", $$v)},expression:"reportData.bookingStatusIds"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2490430590)}):_vm._e(),(_vm.reportType.isPosting)?_c('validation-provider',{attrs:{"name":"Post Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Post Status","label-for":"post-status"}},[_c('v-select',{attrs:{"options":_vm.postStatusList,"label":"name","reduce":function (status) { return status.id; },"input-id":"post-status","multiple":""},model:{value:(_vm.reportData.postStatusIds),callback:function ($$v) {_vm.$set(_vm.reportData, "postStatusIds", $$v)},expression:"reportData.postStatusIds"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3589159407)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }