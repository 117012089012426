<template>
  <b-modal
    ref="refModal"
    id="company-invoice-modal"
    title="Company Invoice"
    no-close-on-backdrop
  >
    <validation-observer ref="companyInvoiceForm">
      <b-form id="">
        <b-tabs
          justified
          pills
        >
          <b-tab
            title="Date Rage"
            active
          >
            <b-form-group
              label="Date From"
              label-for="date-to"
            >
              <flat-pickr
                v-model="dateFrom"
                class="form-control"
                :config="{ dateFormat: 'd/m/Y', enableTime: false }"
              />
            </b-form-group>

            <b-form-group
              label="Date To"
              label-for="date-to"
            >
              <flat-pickr
                v-model="dateTo"
                class="form-control"
                :config="{ dateFormat: 'd/m/Y', enableTime: false }"
              />
            </b-form-group>
          </b-tab>

          <b-tab title="Voucher">
            <b-form-group
              label="Voucher"
              label-for="voucher"
            >
              <b-input-group class="input-group-merge invoice-edit-input-group">
                <b-input-group-prepend is-text>
                  <feather-icon icon="HashIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="voucher"
                  v-model="voucherNum"
                  type="number"
                />
              </b-input-group>
            </b-form-group>
          </b-tab>
        </b-tabs>

        <validation-provider
          #default="{ errors }"
          name="Booking Status"
          rules="required"
        >
          <b-form-group
            label="Booking Status"
            label-for="booking-status"
          >
            <v-select
              v-model="bookingStatusIds"
              :options="bookingStatusList"
              label="name"
              :reduce="(status) => status.id"
              input-id="booking-status"
              multiple
            >
              <template #option="{ name, id }">
                <div
                  :class="`bg-${getBookingStatusVariant(id)}`"
                  class="rounded-circle d-inline-block mr-50"
                  style="height: 10px; width: 10px"
                />
                <span> {{ name }}</span>
              </template>

              <template #selected-option="{ name, id }">
                <div
                  :class="`bg-${getBookingStatusVariant(id)}`"
                  class="rounded-circle d-inline-block mr-50"
                  style="height: 10px; width: 10px"
                />
                <span> {{ name }}</span>
              </template>
            </v-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <b-form-group
          label="Emails"
          label-for="company-email"
        >
          <v-select
            v-model="companyEmaiSelected"
            :options="companyEmailList"
            input-id="transaction-status"
            multiple
          >
            <template #option="data">
              <span> {{ data.label }}</span>
            </template>

            <template #selected-option="data">
              <span> {{ data.label }}</span>
            </template>
          </v-select>

        </b-form-group>
      </b-form>
    </validation-observer>

    <template #modal-footer="{ ok, cancel, hide }">
      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>

      <b-button
        variant="outline-primary"
        @click="validateFormToDownload"
      >
        Download
      </b-button>

      <b-button
        variant="primary"
        @click="validateFormToSend"
      >
        Send
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BTabs, BTab } from 'bootstrap-vue';
import { ref } from "@vue/composition-api";
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import flatPickr from 'vue-flatpickr-component';
import router from '@/router';
import useCompanyBooking from '@/modules/company/composables/useCompanyBooking';
import vSelect from 'vue-select';
import useUtils from '@/composables/useUtils';
import useBookingUtils from '@/modules/booking/composables/useBookingUtils';
import useBookingStatus from '@/modules/booking/composables/useBookingStatus';

export default {
  props: {
    companyEmails: {
      type: String,
    },
  },
  setup(props) {
    const { convertDateToUTC } = useUtils();
    const { getBookingStatusList } = useBookingStatus();
    const { getBookingStatusVariant } = useBookingUtils();
    const { downloadInvoiceCompany, sendInvoiceCompany } = useCompanyBooking();

    const dateFrom = ref('');
    const dateTo = ref('');
    const bookingStatusIds = ref([]);
    const bookingStatusList = ref([]);
    const companyEmailList = ref([]);
    const companyEmaiSelected = ref([]);
    const refModal = ref(null);
    const companyInvoiceForm = ref(null);
    const voucherNum = ref(null);

    const initData = async () => {
      const bookingStatus = await getBookingStatusList();
      bookingStatusList.value = bookingStatus;

      if (props.companyEmails)
        companyEmailList.value = props.companyEmails.split(', ');
    }

    const validateFormToSend = (modalEvt) => {
      modalEvt.preventDefault();

      if (companyEmaiSelected.length === 0) return

      companyInvoiceForm.value.validate().then(async (valid) => {
        if (!valid) return;
        const params = {
          voucherNum: !voucherNum.value ? null : voucherNum.value,
          dateFrom: convertDateToUTC(dateFrom.value),
          dateTo: convertDateToUTC(dateTo.value),
          bookingStatusIds: bookingStatusIds.value,
          emailList: companyEmaiSelected.value,
        };
        const response = await sendInvoiceCompany(router.currentRoute.params.id, params);
        if (response !== null) refModal.value.hide();
      });
    };

    const validateFormToDownload = (modalEvt) => {
      modalEvt.preventDefault();

      companyInvoiceForm.value.validate().then(async (valid) => {
        if (!valid) return;

        const params = {
          voucherNum: !voucherNum.value ? null : voucherNum.value,
          dateFrom: convertDateToUTC(dateFrom.value),
          dateTo: convertDateToUTC(dateTo.value),
          bookingStatusIds: bookingStatusIds.value
        };
        const response = await downloadInvoiceCompany(router.currentRoute.params.id, params);

        if (response !== null) {
          // create file link in browser's memory
          const url = URL.createObjectURL(response.data);

          // create "a" HTLM element with href to file & click
          const link = document.createElement('a');
          link.href = url;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
          refModal.value.hide();
        }
      });
    };

    initData();
    return {
      // Reactive
      dateFrom,
      dateTo,
      bookingStatusIds,
      refModal,
      bookingStatusIds,
      bookingStatusList,
      companyEmailList,
      companyEmaiSelected,
      companyInvoiceForm,
      voucherNum,

      // Method
      getBookingStatusVariant,
      validateFormToSend,
      validateFormToDownload,

      // Validator
      required
    };
  },
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BTabs,
    BTab,
    flatPickr,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#company-invoice {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>