var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"card-payment",attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v("Company Balance")]),_c('b-card-title',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.companyBalance)+" ")])],1),_c('b-card-body',[_c('b-row',{staticClass:"pb-75"},[_c('b-col',[_c('b-form-radio',{attrs:{"name":"charge-transaction","value":false},model:{value:(_vm.chargeTransaction),callback:function ($$v) {_vm.chargeTransaction=$$v},expression:"chargeTransaction"}},[_vm._v(" Debit ")])],1),_c('b-col',[_c('b-form-radio',{attrs:{"name":"charge-transaction","value":true},model:{value:(_vm.chargeTransaction),callback:function ($$v) {_vm.chargeTransaction=$$v},expression:"chargeTransaction"}},[_vm._v(" Credit ")])],1)],1),_c('validation-observer',{ref:"newTransactionPayoutForm"},[_c('b-form',{staticClass:"h-100",attrs:{"id":"add-new-transaction-payout"},on:{"submit":function($event){$event.preventDefault();return _vm.validateForm()}}},[_c('validation-provider',{attrs:{"name":"Amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Amount","label-for":"amount"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"£"}},[_c('b-form-input',{attrs:{"id":"amount","type":"number","step":".01","max":"100000","min":"0","placeholder":"Amount","state":errors.length > 0 ? false : null,"name":"amount"},model:{value:(_vm.transactionData.amount),callback:function ($$v) {_vm.$set(_vm.transactionData, "amount", $$v)},expression:"transactionData.amount"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Transaction Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Transaction Type","label-for":"transaction-type"}},[_c('v-select',{attrs:{"options":_vm.transactionTypes,"label":"name","reduce":function (transactionType) { return transactionType.id; },"input-id":"transaction-type"},model:{value:(_vm.transactionData.transactionTypeId),callback:function ($$v) {_vm.$set(_vm.transactionData, "transactionTypeId", $$v)},expression:"transactionData.transactionTypeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Taxi","label-for":"taxi-list"}},[_c('v-select',{attrs:{"options":_vm.taxiList,"reduce":function (taxi) { return taxi.id; },"input-id":"taxi-list","label":"licenceNum"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
var licenceNum = ref.licenceNum;
return [_c('span',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(licenceNum))]),_vm._v(" - "),_c('small',[_vm._v(_vm._s((firstName + " " + lastName + " ")))])])]}},{key:"selected-option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
var licenceNum = ref.licenceNum;
return [_c('span',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(licenceNum))]),_vm._v(" - "),_c('small',[_vm._v(_vm._s((firstName + " " + lastName + " ")))])])]}}]),model:{value:(_vm.transactionData.taxiId),callback:function ($$v) {_vm.$set(_vm.transactionData, "taxiId", $$v)},expression:"transactionData.taxiId"}})],1),_c('b-form-group',{attrs:{"label":"Account","label-for":"account-list"}},[_c('v-select',{attrs:{"options":_vm.accountList,"label":"name","reduce":function (account) { return account.id; },"input-id":"account-list"},model:{value:(_vm.transactionData.accountId),callback:function ($$v) {_vm.$set(_vm.transactionData, "accountId", $$v)},expression:"transactionData.accountId"}})],1),_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('b-form-input',{staticClass:"mb-3",attrs:{"id":"description","placeholder":"Description","name":"description"},model:{value:(_vm.transactionData.description),callback:function ($$v) {_vm.$set(_vm.transactionData, "description", $$v)},expression:"transactionData.description"}})],1),_c('div',{staticClass:"btn-wrapper-card"},[_c('b-button',{attrs:{"block":"","variant":"primary","type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":"","variant":"light"}}):_vm._e(),_vm._v(" Create ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }