import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useCompanyReport = () => {
  const toast = useToast();

  const createReport = async (report) => {
    try {
      return await store.dispatch('companyReports/create', report);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error creating the company report',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const editReport = async (report) => {
    try {
      await store.dispatch('companyReports/update', report);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error updating the company report',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const getCompanyReportList = async (companyReport) => {
    try {
      return await store.dispatch(
        'companyReports/getReportList',
        companyReport
      );
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the company reports',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };
  return {
    createReport,
    editReport,
    getCompanyReportList,
  };
};

export default useCompanyReport;
