<template>
  <b-modal
    id="company-auto-report-list-modal"
    ref="refModal"
    size="lg"
    title="Company Auto. Report List"
    ok-only
    ok-title="Close"
  >
    <div class="d-flex justify-content-end mt-1 mb-1">
      <b-button
        size="sm"
        variant="primary"
        @click="resetReportData"
        v-b-modal.company-auto-report-form-modal
      >
        Add new
      </b-button>
    </div>

    <b-card
      class="standing-order-list"
      no-body
    >
      <b-card-body>
        <div
          v-for="report in autoReportList"
          :key="report.id"
          class="standing-order-item"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                :variant="(report.isActive) ? 'light-primary' : 'light-secondary'"
              >
                <feather-icon
                  size="18"
                  icon="PocketIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="w-100">
              <div class="d-flex align-items-center mb-25">
                <h6 class="mb-0">{{ report.reportType.name }}</h6>
                <div class="ml-1">
                  <b-badge
                    :variant="(report.isActive) ? 'light-primary' : 'light-secondary'"
                    class="text-capitalize"
                  >
                    {{ frequencyName(report) }}
                  </b-badge>
                </div>
              </div>
              <small v-if="report.isActive">
                Next Run Date: {{ (report.lastReportExecution === null)
                  ? formatReportDate(report.startDateUtc)
                  : formatReportDate(report.lastReportExecution.nextRunDateUtc) }}
              </small>
            </b-media-body>
          </b-media>

          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreHorizontalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="manageReport(report)"
              v-b-modal.company-auto-report-form-modal
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Manage</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-card-body>
    </b-card>


    <company-report-form-modal
      :report="reportData"
      @form-submitted="companyAutoReportFormSubmitted"
    />
  </b-modal>
</template>

<script>
import { BAvatar, BBadge, BButton, BCard, BCardBody, BCardText, BDropdown, BDropdownItem, BMedia, BMediaAside, BMediaBody, BModal } from "bootstrap-vue";
import { computed, ref } from "@vue/composition-api";
import router from '@/router';
import store from '@/store';
import CompanyReportFormModal from '@/modules/company/components/modals/CompanyReportFormModal.vue';
import useCompanyReport from '@/modules/company/composables/useCompanyReport';
import useUtils from '@/composables/useUtils';

export default {
  setup() {
    const { formatDateDdMmYyyy, formatCurrency } = useUtils()
    const { getCompanyReportList } = useCompanyReport();

    const autoReportList = computed(() => store.getters['companyReports/reports']);
    const formatReportDate = (date) => formatDateDdMmYyyy(date);

    const reportData = ref({
      id: null,
      reportTypeId: null,
      isDaily: null,
      isWeekly: null,
      isMonthly: null,
      isYearly: null,
      companyId: router.currentRoute.params.id,
      isActive: null,
      startDateUtc: new Date(),
      transactionStatusIds: [],
      bookingStatusIds: [],
      postStatusIds: [],
      lastReportExecution: null,
    });

    const manageReport = (data) => {
      reportData.value = { ...data };
    };

    const frequencyName = (data) => {
      if (data.isDaily) return 'Daily';
      if (data.isWeekly) return 'Weekly';
      if (data.isMonthly) return 'Monthly';
      if (data.isYearly) return 'Yearly';
    }

    const resetReportData = () => {
      reportData.value = {
        id: null,
        reportTypeId: null,
        isDaily: null,
        isWeekly: null,
        isMonthly: null,
        isYearly: null,
        companyId: router.currentRoute.params.id,
        isActive: null,
        startDateUtc: new Date(),
        transactionStatusIds: [],
        bookingStatusIds: [],
        postStatusIds: [],
        lastReportExecution: null,
      };
    };

    const companyAutoReportFormSubmitted = async (_) => {
      const companyId = router.currentRoute.params.id;
      await getCompanyReportList(companyId);
    };

    return {
      // Reactive
      reportData,
      autoReportList,
      formatReportDate,

      // Method
      manageReport,
      formatCurrency,
      frequencyName,
      resetReportData,
      companyAutoReportFormSubmitted
    };
  },
  components: {
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardText,
    BDropdown,
    BDropdownItem,
    BMedia,
    BMediaAside,
    BMediaBody,
    BModal,
    CompanyReportFormModal
  }
};
</script>

<style>
.standing-order-list .standing-order-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.standing-order-list .standing-order-item:not(:last-child) {
  margin-bottom: 1.2rem;
}

[dir=ltr] .standing-order-list .standing-order-item .avatar {
  margin-right: 1.2rem;
}

[dir=rtl] .standing-order-list .standing-order-item .avatar {
  margin-left: 1.2rem;
}

.standing-order-list .standing-order-item .avatar .avatar-content {
  width: 42px;
  height: 42px;
}
</style>