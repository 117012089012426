import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useCompanyPost = () => {
  const toast = useToast();

  const downloadPostCompany = async (companyId, params) => {
    try {
      return await store.dispatch('company/downloadPostCompany', {
        companyId,
        params,
      });
    } catch (error) {
      const title =
        error.response.status === 404
          ? 'No post found in date range'
          : 'Error downloading the company posts';
      toast.error({
        component: ToastificationContent,
        props: {
          title,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const sendPostCompany = async (companyId, params) => {
    try {
      await store.dispatch('company/sendPostCompany', {
        companyId,
        params,
      });

      toast.success({
        component: ToastificationContent,
        props: {
          title: 'Email sent successfully!',
          icon: 'CheckIcon',
        },
      });
      return true;
    } catch (error) {
      const title =
        error.response.status === 404
          ? 'No post found in date range'
          : 'Error sending the email';
      toast.error({
        component: ToastificationContent,
        props: {
          title,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  return {
    // Method
    downloadPostCompany,
    sendPostCompany,
  };
};

export default useCompanyPost;
