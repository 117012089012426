<template>
  <b-modal
    ref="refModal"
    id="company-auto-report-form-modal"
    title="Report Details"
    :ok-title="(reportData.id) ? 'Update' : 'Add'"
    no-close-on-backdrop
    cancel-variant="outline-secondary"
    @ok="validateForm"
  >
    <validation-observer ref="newCompanyReportFrom">
      <b-form id="add-new-company-report">
        <validation-provider
          #default="{ errors }"
          name="Report Type"
          rules="required"
        >
          <b-form-group
            label="Report Type"
            label-for="report-type"
          >
            <v-select
              @input="(reportTypeId) => reportSelected(reportTypeId)"
              v-model="reportData.reportTypeId"
              :options="reportTypes"
              label="name"
              :reduce="(options) => options.id"
              input-id="report-type"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Frequency"
          rules="required"
        >
          <b-form-group
            label="Frequency"
            label-for="frequency"
          >
            <v-select
              v-model="frequencyValue"
              :options="frequencyList"
              label="label"
              :reduce="(options) => options.value"
              input-id="frequency"
              @input="(option) => frequencySelected(option)"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Is Active"
          rules="required"
        >
          <b-form-group
            label="Is Active"
            label-for="is-active"
          >
            <v-select
              v-model="reportData.isActive"
              :options="[{ label: 'Yes', value: true }, { label: 'No', value: false }]"
              label="label"
              :reduce="(options) => options.value"
              input-id="is-active"
            >
              <template #option="{ label, value }">
                <div
                  class="rounded-circle d-inline-block mr-50"
                  :class="(value) ? 'bg-primary' : 'bg-secondary'"
                  style="height: 10px; width: 10px"
                />
                <span> {{ label }}</span>
              </template>

              <template #selected-option="{ label, value }">
                <div
                  class="rounded-circle d-inline-block mr-50"
                  :class="(value) ? 'bg-primary' : 'bg-secondary'"
                  style="height: 10px; width: 10px"
                />
                <span> {{ label }}</span>
              </template>
            </v-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Date From"
          rules="required"
        >
          <b-form-group
            label="Date From"
            label-for="date-to"
          >
            <flat-pickr
              v-model="reportData.startDateUtc"
              class="form-control"
              :config="{ dateFormat: 'd/m/Y', enableTime: false }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-if="reportType.isTransaction"
          #default="{ errors }"
          name="Transactions Status"
          rules="required"
        >
          <b-form-group
            label="Transaction Status"
            label-for="transaction-status"
          >
            <v-select
              v-model="reportData.transactionStatusIds"
              :options="transactionStatusList"
              label="name"
              :reduce="(transaction) => transaction.id"
              input-id="transaction-status"
              multiple
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-if="reportType.isBooking"
          #default="{ errors }"
          name="Booking Status"
          rules="required"
        >
          <b-form-group
            label="Booking Status"
            label-for="booking-status"
          >
            <v-select
              v-model="reportData.bookingStatusIds"
              :options="bookingStatusList"
              label="name"
              :reduce="(status) => status.id"
              input-id="booking-status"
              multiple
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-if="reportType.isPosting"
          #default="{ errors }"
          name="Post Status"
          rules="required"
        >
          <b-form-group
            label="Post Status"
            label-for="post-status"
          >
            <v-select
              v-model="reportData.postStatusIds"
              :options="postStatusList"
              label="name"
              :reduce="(status) => status.id"
              input-id="post-status"
              multiple
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput, BInputGroup } from 'bootstrap-vue';
import { ref, watch } from "@vue/composition-api";
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import flatPickr from 'vue-flatpickr-component';
import useBookingStatus from '@/modules/booking/composables/useBookingStatus';
import useCompanyReport from '@/modules/company/composables/useCompanyReport';
import useCompanyReportType from '@/modules/company/composables/useCompanyReportType';
import useCompanyTransaction from '@/modules/company/composables/useCompanyTransaction';
import usePostStatus from '@/modules/post/composables/usePostStatus';
import useUtils from '@/composables/useUtils';
import vSelect from 'vue-select';

export default {
  props: {
    report: {
      type: Object
    }
  },
  setup(props, ctx) {
    const { convertDateToUTC, formatDateDdMmYyyy } = useUtils();
    const { createReport, editReport } = useCompanyReport();
    const { getReportTypeList } = useCompanyReportType();
    const { fetchTransactionStatus } = useCompanyTransaction();
    const { getBookingStatusList } = useBookingStatus();
    const { getPostStatusList } = usePostStatus();

    const frequencyValue = ref(null);
    const newCompanyReportFrom = ref(null);
    const refModal = ref(null);
    const reportData = ref({ ...props.report });
    const reportTypes = ref([]);
    const reportType = ref({ isBooking: false, isTransaction: false, isPosting: false });
    const transactionStatusList = ref([]);
    const bookingStatusList = ref([]);
    const postStatusList = ref([]);
    const frequencyList = [
      { label: 'Daily', value: 'isDaily' },
      { label: 'Weekly', value: 'isWeekly' },
      { label: 'Monthly', value: 'isMonthly' },
      { label: 'Yearly', value: 'isYearly' }
    ];

    watch(() => props.report, (value, _) => {
      frequencyValue.value = getFrequencyValue(value);
      reportType.value = (value.id === null) ? { isBooking: false, isTransaction: false, isPosting: false } : reportTypes.value.find(reportType => reportType.id === value.reportTypeId)
      reportData.value = value;
      reportData.value.startDateUtc = formatDateDdMmYyyy(value.startDateUtc);
    });

    const validateForm = (modalEvt) => {
      modalEvt.preventDefault();

      newCompanyReportFrom.value.validate().then(async (valid) => {
        if (!valid) return;

        const report = { ...reportData.value, startDateUtc: convertDateToUTC(reportData.value.startDateUtc) };

        const response = (reportData.value.id === null)
          ? await createReport(report)
          : await editReport(report);

        if (response !== null) {
          ctx.emit('form-submitted');
          refModal.value.hide();
        }
      });
    };

    const reportSelected = (reportTypeId) => {
      reportType.value = reportTypes.value.find(reportType => reportType.id === reportTypeId);
      reportData.value.transactionStatusIds = [];
      reportData.value.bookingStatusIds = [];
      reportData.value.postStatusIds = [];
    }

    const getFrequencyValue = (data) => {
      if (data.isDaily) return 'isDaily';
      if (data.isWeekly) return 'isWeekly';
      if (data.isMonthly) return 'isMonthly';
      if (data.isYearly) return 'isYearly';
    }

    const frequencySelected = (data) => {
      reportData.value.isDaily = false;
      reportData.value.isWeekly = false;
      reportData.value.isMonthly = false;
      reportData.value.isYearly = false;

      if (data === 'isDaily') reportData.value.isDaily = true
      if (data === 'isWeekly') reportData.value.isWeekly = true
      if (data === 'isMonthly') reportData.value.isMonthly = true
      if (data === 'isYearly') reportData.value.isYearly = true
    }

    const initData = async () => {
      const reportTypeData = await getReportTypeList();
      const transactionStatus = await fetchTransactionStatus();
      const bookingStatus = await getBookingStatusList();
      const postStatus = await getPostStatusList();
      postStatusList.value = postStatus;
      bookingStatusList.value = bookingStatus;
      transactionStatusList.value = transactionStatus;
      reportTypes.value = reportTypeData.reportTypes;
    }

    initData()

    return {
      // Reactive
      frequencyList,
      frequencyValue,
      newCompanyReportFrom,
      refModal,
      reportData,
      reportTypes,
      reportType,
      transactionStatusList,
      bookingStatusList,
      postStatusList,

      // Method
      frequencySelected,
      validateForm,
      reportSelected,

      // Validator
      required
    };
  },
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    flatPickr,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

@import '@core/scss/vue/libs/vue-select.scss';

#add-new-company-report {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>