<template>
  <div class="d-flex flex-row justify-content-end mb-1">
    <b-dropdown
      variant="link"
      no-caret
      toggle-class="p-0"
      right
    >

      <template #button-content>
        <b-button
          variant="primary"
          class="btn-icon"
        >
          <feather-icon icon="SettingsIcon" />
        </b-button>
      </template>

      <b-dropdown-item @click="openCompamyAutoReportModal">
        <feather-icon
          icon="PocketIcon"
          size="16"
        />
        <span class="align-middle ml-50">Auto. Reports</span>
      </b-dropdown-item>
    </b-dropdown>
    <company-report-list-modal ref="companyAutoReportModalRef" />
  </div>
</template>

<script>
import { BButton, BDropdown, BDropdownItem } from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import CompanyReportListModal from '@/modules/company/components/modals/CompanyReportListModal.vue';
import useCompanyReport from '../composables/useCompanyReport';
import router from '@/router';

export default {
  setup() {
    const { getCompanyReportList } = useCompanyReport();

    const companyAutoReportModalRef = ref(null);

    const openCompamyAutoReportModal = () => {
      companyAutoReportModalRef.value.$refs.refModal.show();
    }

    getCompanyReportList(router.currentRoute.params.id);

    return {
      // Reactive
      companyAutoReportModalRef,

      // Method
      openCompamyAutoReportModal
    };
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    CompanyReportListModal
  }
};
</script>